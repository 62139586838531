import React, { useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import BuyButton from "./BuyButton";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1];
const trans = (x, y, s) => `perspective(2000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const StoryCard = ({ headTitle, data, img, market = "" }) => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: config.default }));

    return (
        <animated.div
            className="group w-[90vw] md:w-[40vw] bg-white rounded-lg bg-opacity-10 backdrop-blur-sm p-3 md:p-8 flex justify-between flex-col "
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{
                transform: props.xys.to(trans),
            }}
        >
            <div className="flex flex-col items-center justify-center gap-y-2 md:gap-y-7">
                <img
                    className="rounded-lg  cursor-pointer duration-500 w-20  md:w-40 group-hover:scale-[1.03]  md:group-hover:scale-[1.07]  group-hover:drop-shadow-[-7px_-9px_2px_rgba(0,0,0,0.5)] md:group-hover:drop-shadow-[-14px_-18px_4px_rgba(0,0,0,0.5)]"
                    src={img}
                    alt="Storyimg"
                />
                <p className="capitalize text-16-24 font-semibold  text-white duration-500 group-hover:scale-[1.03]  md:group-hover:scale-[1.07]  group-hover:drop-shadow-[-7px_-9px_2px_rgba(0,0,0,0.5)] md:group-hover:drop-shadow-[-14px_-18px_4px_rgba(0,0,0,0.5)]">
                    {headTitle}
                </p>

                <p className="text-12-16 font-medium text-white  duration-500 group-hover:scale-[1.03]  md:group-hover:scale-[1.07]  group-hover:drop-shadow-[-3px_-5px_2px_rgba(0,0,0,0.5)] md:group-hover:drop-shadow-[-14px_-18px_4px_rgba(0,0,0,0.5)]">
                    {data}
                </p>
                <BuyButton
                    extra={
                        "duration-500 group-hover:scale-[1.03]  md:group-hover:scale-[1.07]  group-hover:drop-shadow-[-7px_-9px_2px_rgba(0,0,0,0.5)] md:group-hover:drop-shadow-[-14px_-18px_4px_rgba(0,0,0,0.5)]"
                    }
                    market={market}
                />
            </div>
        </animated.div>
    );
};

export default StoryCard;
