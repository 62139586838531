const Partners = () => {
    const partners = ["p0", "p7", "p4", "p3", "p2", "p5", "p6", "p1"];
    return (
        <div className="relative bg-background-radial-gradient  pb-40 ">
            <div className="absolute -top-[10%] -right-[15%] w-[175px] md:w-[455px] h-[125px] md:h-[455px] bg-[#D622DA95] rounded-full blur-[147px]" />
            <div className="absolute -bottom-[13%] -left-[10%] w-[175px] md:w-[355px] h-[125px] md:h-[355px] bg-[#D622DA95] rounded-full blur-[147px]" />

            <div className="flex justify-center items-center w-[90%] mx-auto">
                <div>
                    <div>
                        <div className="flex justify-center items-center gap-x-3">
                            <img src="/assets/partners/kathir.svg" className="" alt="" />
                            <h1 className=" font-extrabold z-20 text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)] ">
                                OUR PARTNERS
                            </h1>
                            <img src="/assets/partners/kathir2.svg" className="" alt="" />
                        </div>
                        <p className=" font-medium text-white text-xl mt-5  w-[60%] text-center mx-auto">
                            Partners in Progress: Uniting Forces for Innovation, Creativity, and a Dash of
                            Technological Wizardry!
                        </p>
                    </div>
                    {/* <div className="grid grid-cols-3 gap-10 mx-auto border">
                        {partners.map((img, i) => (
                            <img key={i} src={`/assets/partners/${img}.svg`} className="self-center mx-auto p-4 border"/>
                        ))}
                    </div> */}
                    <div className="grid grid-cols-4 grid-rows-2 gap-10 mx-auto mt-[60px]">
                        {partners.map((img, i) => (
                            <div
                                key={i}
                                className=" flex justify-center items-center p-4 rounded-2xl bg-[#ffffff0d]"
                            >
                                <img src={`/assets/partners/${img}.png`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;
