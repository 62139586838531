import "./Story.css";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const Story = () => {
    useGSAP(() => {
        gsap.to(".img", {
            scrollTrigger: {
                trigger: ".img",
                start: "top 100px",
                // end: () => "+=" + document.querySelector(".img").offsetHeight,
                end: "150% ",
                toggleActions: "restart pause reverse pause",
                pin: true,
                scrub: true,
            },
            scale: 2.5,
            translateY: "50%",
        });

        gsap.to(".hero-text", {
            scrollTrigger: {
                trigger: ".hero-text",
                id: "yxy",
                start: "50px 100px",
                end: "500px ",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 5,
        });
    });

    return (
        <div className="bg-background-radial-gradient " id="ourstory">
            <div className="h-[100vh] w-full">
                <div className={`relative flex items-center justify-center h-screen `}>
                    <div className="relative w-[50%]  ">
                        <img src="/assets/hero-image.png" className=" opacity-0 border" />
                        <img
                            src="/assets/hero-sub-img-1.png"
                            className="absolute w-[35%]  -top-1/4 -left-[5%]"
                        />
                        <img
                            src="/assets/hero-sub-img-2.png"
                            className="absolute w-[41%] lefty -top-1/4 -right-[20%]"
                        />
                        <img
                            src="/assets/hero-sub-img-3.png"
                            className="absolute w-[39%]  -bottom-0 -left-[10%]"
                        />
                        <img
                            src="/assets/hero-sub-img-4.png"
                            className="absolute w-[43%] lefty -bottom-0 -right-[10%]"
                        />
                        ////
                        {/* <img src="/assets/hero-image.png" className="absolute inset-0 img" /> */}
                        <div className="absolute inset-0  w-[80%] bg-center bg-no-repeat  mx-auto  rounded-2xl img ">
                            <div className="w-full rounded-2xl whitespace-nowrap overflow-hidden imgg">
                                <div className="inline-block animate-slide">
                                    <img src="/assets/hero-image.png" className="inline-block " />
                                </div>
                                <div className="inline-block animate-slide">
                                    <img src="/assets/hero-image.png" className="inline-block " />
                                </div>
                                <div className="inline-block animate-slide">
                                    <img src="/assets/hero-image.png" className="inline-block " />
                                </div>
                            </div>
                            <div className="absolute inset-0 flex justify-center items-center">
                                <div className="text-center scale-100 hero-text opacity-0">
                                    <h1 className="font-extrabold text-[#E9FF00] text-11-23 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                                        
                                    </h1>
                                    <p className="font-medium text-white text-9-3 md:w-[70%] mx-auto mt-2 md:mt-6 mb-1 md:mb-4">
                                        
                                    </p>
                                    <p className="font-medium text-white text-9-3 md:w-[70%] mx-auto">
                                       
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[44vh]"></div>
        </div>
    );
};

export default Story;
