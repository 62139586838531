import React from "react";

const BuyButton = ({ extra = "", market = "" }) => {
    if (market === "magic_eden") {
        return (
            <a
                href="https://inscribenow.io/collections/2240f319a9b903b6"
                target="_blank"
                className={`font-kanit font-medium text-12-16 text-black  py-1 md:py-2 px-3 md:px-5 bg-[#E9FF00] rounded-full ${extra}`}
            >
                Mint on InscribeNow
            </a>
        );
    } else {
        return (
            <a
                href="https://opensea.io/collection/metadukesnft"
                target="_blank"
                className={`font-kanit font-medium text-12-16 text-black  py-1 md:py-2 px-3 md:px-5 bg-[#E9FF00] rounded-full ${extra}`}
            >
                Buy on OpenSea
            </a>
        );
    }
};

export default BuyButton;
