import React, { useState } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const OurEcoSystem = () => {
    useGSAP(() => {
        gsap.to(".ringOne", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 30%",
                end: "100px 35%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 1,
        });
        gsap.to(".ringTwo", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 25%",
                end: "100px 30%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 2,
        });
        gsap.to(".ringthree", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 18%",
                end: "100px 23%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 3,
        });
        gsap.to(".ringFour", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 15%",
                end: "100px 20%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 4,
        });
        gsap.to(".ringFive", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 10%",
                end: "100px 17%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 5,
        });
        gsap.to(".ringSix", {
            scrollTrigger: {
                trigger: ".cnt",
                start: "top 9%",
                end: "100px 17%",
                toggleActions: "restart pause reverse pause",
                scrub: true,
            },
            opacity: 1,
            duration: 6,
        });
    });

    const [showMetaDukes, setshowMetaDukes] = useState(false);
    const [showRuinedDukes, setshowRuinedDukes] = useState(false);
    return (
        <>
            <div className="pt-20">

            </div>
            <div className="relative bg-background-radial-gradient pt-8 md:h-[1000px] flex items-center flex-col    cnt">
                <div className=" md:hidden  mb-6">
                    <h1 className="  font-extrabold text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                        OUR ECOSYSTEM
                    </h1>
                    <p className=" text-xs font-medium  text-center text-white md:mt-2">
                    KNOWP Token, Security Token Offering (STO), The Base Network
                    </p>
                    <p className="text-xs font-medium text-center text-white">
                    </p>
                </div>
                <img src="/assets/ecosystem/rings2.png" alt=""/>
                <div className="flex justify-center w-full pt-16 ">
                    {/* <img
                        className="absolute top-[60%] left-[47%] opacity-0 ringOne  md:w-[173px] hidden md:block "
                        src="/assets/ecosystem/ringOne.svg"
                        alt="ringOne"
                    />
                    <img
                        className="absolute top-[46%] left-[40%] ringTwo opacity-0 md:w-[393px] hidden md:block "
                        src="/assets/ecosystem/ringTwo.svg"
                        alt="ringTwo"
                    />
                    <img
                        className="absolute top-[34%] left-[34%] opacity-0 ringthree md:w-[579px] hidden md:block "
                        src="/assets/ecosystem/ringThree.svg"
                        alt="ringthree"
                    />
                    <img
                        className="absolute top-[35%] left-[34%]  ringFour opacity-0 md:w-[579px] hidden md:block "
                        src="/assets/ecosystem/ringFour.svg"
                        alt="ringFour"
                    />
                    <img
                        className="absolute top-[1%] opacity-0 ringFive md:w-[994] hidden md:block "
                        src="/assets/ecosystem/ringFive.svg"
                        alt="ringFive"
                    />
                    <img
                        className="absolute top-[0%] opacity-0 ringSix md:w-[994] hidden md:block "
                        src="/assets/ecosystem/ringSix.svg"
                        alt="ringSix"
                    />

                    <h3
                        onMouseEnter={() => setshowMetaDukes(true)}
                        onMouseLeave={() => setshowMetaDukes(false)}
                        className="cursor-pointer top-[51%] left-[7%] md:left-[26%] absolute  font-extrabold text-center text-[#E9FF00] text-16-32 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]"
                    >
                        COMPONENTS
                    </h3>
                    <h3
                        onMouseEnter={() => setshowRuinedDukes(true)}
                        onMouseLeave={() => setshowRuinedDukes(false)}
                        className="cursor-pointer top-[51%] left-[66%] md:left-[64%] absolute font-extrabold text-center text-[#E9FF00] text-16-32 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]"
                    >
                        ECONOMICS
                    </h3> */}

                    {/* add hover to show tooltip like box */}
                    {showMetaDukes && (
                        <img
                            className="absolute z-10 top-[13%] md:top-[11%]  left-[8%] md:left-[25%] w-[320px]   md:w-[700px]"
                            src="/assets/ecosystem/componentsDialog.png"
                            alt="metadukesDialogImage"
                        />
                    )}
                    {showRuinedDukes && (
                        <img
                            className="absolute z-10 md:top-[11%] top-[13%] left-[30%] md:left-[40%] w-[320px]   md:w-[700px]"
                            src="/assets/ecosystem/economiesDialoge.png"
                            alt="ruineddukesDialogImage"
                        />
                    )}
                    {/* <img
                        onMouseEnter={() => setshowMetaDukes(true)}
                        onMouseLeave={() => setshowMetaDukes(false)}
                        className="absolute top-[60%] left-[10%] md:left-[24%] cursor-pointer w-[85px] md:w-[300px]"
                        src="/assets/ecosystem/features.png"
                        alt="featuresImage"
                    />

                    <img
                        onMouseEnter={() => setshowRuinedDukes(true)}
                        onMouseLeave={() => setshowRuinedDukes(false)}
                        className="absolute top-[60%] left-[70%] md:left-[62%]  cursor-pointer w-[85px] md:w-[300px]"
                        src="/assets/ecosystem/economies.png"
                        alt="economiesImage"
                    />

                    <img
                        className="absolute top-[40%] left-[74%] hidden md:block "
                        src="/assets/ecosystem/bubbleTwo.svg"
                        alt="bubbleImage"
                    />
                    <img
                        className="absolute top-[6%] left-[35%] hidden md:block "
                        src="/assets/ecosystem/bubbleOne.svg"
                        alt="bubbleImage"
                    /> */}
                </div>
            </div>
        </>
    );
};

export default OurEcoSystem;

// import React, { useState } from "react";

// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { useGSAP } from "@gsap/react";
// gsap.registerPlugin(useGSAP);
// gsap.registerPlugin(ScrollTrigger);

// const OurEcoSystem = () => {
//     useGSAP(() => {
//         gsap.to(".ringOne", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 30%",
//                 end: "100px 35%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 1,
//         });
//         gsap.to(".ringTwo", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 25%",
//                 end: "100px 30%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 2,
//         });
//         gsap.to(".ringthree", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 18%",
//                 end: "100px 23%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 3,
//         });
//         gsap.to(".ringFour", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 15%",
//                 end: "100px 20%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 4,
//         });
//         gsap.to(".ringFive", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 10%",
//                 end: "100px 17%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 5,
//         });
//         gsap.to(".ringSix", {
//             scrollTrigger: {
//                 trigger: ".cnt",
//                 start: "top 9%",
//                 end: "100px 17%",
//                 toggleActions: "restart pause reverse pause",
//                 scrub: true,
//             },
//             opacity: 1,
//             duration: 6,
//         });
//     });

//     const [showMetaDukes, setshowMetaDukes] = useState(false);
//     const [showRuinedDukes, setshowRuinedDukes] = useState(false);
//     return (
//         <div className="relative bg-background-radial-gradient pt-8 md:h-[800px] flex items-center flex-col   cnt">
//             <div className=" md:hidden  mb-6">
//                 <h1 className="  font-extrabold text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
//                     OUR ECOSYSTEM
//                 </h1>
//                 <p className=" text-xs font-medium  text-center text-white md:mt-2">
//                     Currently, Funs.AI boasts two NFT collections so far:
//                 </p>
//                 <p className="text-xs font-medium text-center text-white">
//                     RuneDukes NFT and MetaDukes NFT.
//                 </p>
//             </div>
//             <img src="/assets/ecosystem/rings.png" alt="" className="md:hidden" />
//             <div className="flex justify-center w-full pt-16 ">
//                 <img
//                     className="absolute top-[60%] left-[47%] opacity-0 ringOne  md:w-[173px] hidden md:block "
//                     src="/assets/ecosystem/ringOne.svg"
//                     alt="ringOne"
//                 />
//                 <img
//                     className="absolute top-[46%] left-[40%] ringTwo opacity-0 md:w-[393px] hidden md:block "
//                     src="/assets/ecosystem/ringTwo.svg"
//                     alt="ringTwo"
//                 />
//                 <img
//                     className="absolute top-[34%] left-[34%] opacity-0 ringthree md:w-[579px] hidden md:block "
//                     src="/assets/ecosystem/ringThree.svg"
//                     alt="ringthree"
//                 />
//                 <img
//                     className="absolute top-[34%] left-[34%]  ringFour opacity-0 md:w-[579px] hidden md:block "
//                     src="/assets/ecosystem/ringFour.svg"
//                     alt="ringFour"
//                 />
//                 <img
//                     className="absolute top-[1%] opacity-0 ringFive md:w-[994] hidden md:block "
//                     src="/assets/ecosystem/ringFive.svg"
//                     alt="ringFive"
//                 />
//                 <img
//                     className="absolute top-[0%] opacity-0 ringSix md:w-[994] hidden md:block "
//                     src="/assets/ecosystem/ringSix.svg"
//                     alt="ringSix"
//                 />

//                 <h3
//                     onMouseEnter={() => setshowMetaDukes(true)}
//                     onMouseLeave={() => setshowMetaDukes(false)}
//                     className="cursor-pointer top-[51%] left-[7%] md:left-[26%] absolute  font-extrabold text-center text-[#E9FF00] text-16-32 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]"
//                 >
//                     METADUKES
//                 </h3>
//                 <h3
//                     onMouseEnter={() => setshowRuinedDukes(true)}
//                     onMouseLeave={() => setshowRuinedDukes(false)}
//                     className="cursor-pointer top-[51%] left-[66%] md:left-[64%] absolute font-extrabold text-center text-[#E9FF00] text-16-32 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]"
//                 >
//                     RUNEDUKES
//                 </h3>

//                 {/* add hover to show tooltip like box */}
//                 {showMetaDukes && (
//                     <img
//                         className="absolute z-10 top-[19%] md:top-[17%]  left-[8%] md:left-[25%] w-[280px]   md:w-[700px]"
//                         src="/assets/ecosystem/metadukesDialog.svg"
//                         alt="metadukesDialogImage"
//                     />
//                 )}
//                 {showRuinedDukes && (
//                     <img
//                         className="absolute z-10 md:top-[11%] top-[13%] left-[30%] md:left-[40%] w-[280px]   md:w-[700px]"
//                         src="/assets/ecosystem/runeddukesDialoge.svg"
//                         alt="ruineddukesDialogImage"
//                     />
//                 )}
//                 <img
//                     onMouseEnter={() => setshowMetaDukes(true)}
//                     onMouseLeave={() => setshowMetaDukes(false)}
//                     className="absolute top-[60%] left-[10%] md:left-[24%] cursor-pointer w-[85px] md:w-[300px]"
//                     src="/assets/ecosystem/metaukes.svg"
//                     alt="metaukesImage"
//                 />

//                 <img
//                     onMouseEnter={() => setshowRuinedDukes(true)}
//                     onMouseLeave={() => setshowRuinedDukes(false)}
//                     className="absolute top-[60%] left-[70%] md:left-[62%]  cursor-pointer w-[85px] md:w-[300px]"
//                     src="/assets/ecosystem/runedDukes.svg"
//                     alt="runedDukesImage"
//                 />

//                 <img
//                     className="absolute top-[40%] left-[74%] hidden md:block "
//                     src="/assets/ecosystem/bubbleTwo.svg"
//                     alt="bubbleImage"
//                 />
//                 <img
//                     className="absolute top-[6%] left-[35%] hidden md:block "
//                     src="/assets/ecosystem/bubbleOne.svg"
//                     alt="bubbleImage"
//                 />
//             </div>
//         </div>
//     );
// };

// export default OurEcoSystem;
