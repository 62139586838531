import React from "react";
import Accordin from "./Accordin";

const Faq = () => {
    let qA = [
        {
            question: "What is Funs.AI?",
            answer: "Funs.AI is a SocialFi platform developed by a Silicon Valley-based startup Knowpia Inc., founded by a team with extensive experience and a successful track record. Our mission is to integrate AI and blockchain technology to create the platform that empowers users and creators. Currently, the team is developing the Funs.ai platform, which will offer an ecosystem combining social economy, digital asset trading, and e-commerce, with a beta launch planned in Q4, 2024.",
        },
        {
            question: "Why Funs.ai adopts STO instead of IEO or IPO",
            answer: "Funs.ai adopts a Security Token Offering (STO) instead of an Initial Exchange Offering (IEO) or Initial Public Offering (IPO) for several strategic reasons. STOs operate within existing securities regulations, providing enhanced regulatory compliance and investor protection through mandatory disclosures and audits. This transparency builds investor trust and attracts serious, long-term investors, including institutional ones. STOs also democratize investment opportunities by allowing participation from both institutional and retail investors globally. They provide liquidity and market efficiency through secondary market trading on regulated exchanges, helping establish fair market values. Compared to IEOs, which often lack stringent compliance, and IPOs, which involve high regulatory costs, STOs offer a more flexible and cost-effective way to raise capital. Additionally, Funs.ai uses STOs to incentivize user engagement, content creation, loyalty, and referrals with KNOWP tokens, fostering an active and vibrant community. This approach ensures regulatory compliance, enhances investor trust, and supports long-term growth and sustainability.",
        },
        {
            question: "How does Funs.ai social platform differ from other popular social platforms like Facebook?",
            answer: "Funs.ai distinguishes itself from traditional social platforms like Facebook through several unique features. By leveraging blockchain technology, Funs.ai creates a decentralized ecosystem where users have more control over their data and content. The platform uses the KNOWP token, enabling a tokenized economy for earning, tipping, and spending within the platform. SocialFi features incentivize active participation and content creation with KNOWP token rewards. Funs.ai also integrates NFTs, allowing creators to showcase and auction digital works directly on the platform. Additionally, the CreditFi system enhances trust by building decentralized identities and reputation scores. Finally, Funs.ai offers innovative monetization opportunities for merchants and ensures robust privacy and security through blockchain and regulatory compliance.",
        },
        {
            question: "How can I benefit by using Funs.ai platform as a content creator or social media user?",
            answer: "As a content creator on Funs.ai, you can monetize your digital content by converting it into NFTs and earning KNOWP tokens from sales and tips. The platform fosters direct interaction with your audience and community building, enhancing your influence and credibility through decentralized identities and reputation scores. As a social media user, you can earn KNOWP tokens for engaging with content and participating in activities. You can also tip creators, access exclusive NFTs, and build your reputation. Funs.ai ensures greater control over your data and interactions, offering a secure and rewarding environment for all users.",
        },
        {
            question: "How does Funs.ai marketplace platform differ from other popular marketplace platforms like Opensea?",
            answer: "Funs.ai distinguishes itself from OpenSea by integrating social networking features into its marketplace, allowing users to engage with content, follow creators, participate in live streams, and earn incentives. Transactions are conducted using the KNOWP token, enhancing economic stability. The CreatorFi platform supports seamless NFT creation and auctions, while CreditFi builds decentralized identities and reputation scores for users. Merchants can use NFT-based redeemable vouchers, and platform-generated gift NFTs enhance fan engagement. Funs.ai prioritizes regulatory compliance and security, providing a more interactive, community-driven, and secure marketplace environment.",
        },
        {
            question: "What role will Dukes NFT play on the Funs.ai platform in the future?",
            answer: "Dukes NFT will play multiple important roles on the Funs.ai platform, from unique digital collectibles to sources of income for creators, to tools for platform activities and community building. RuneDukes and MetaDukes NFTs, as premium passes, will provide users with additional privileges and access rights. Funs.AI will ensure ongoing updates and collaborations, empowering NFTs with diverse application scenarios and practical value, creating an active, interactive, and attractive ecosystem that offers more opportunities and value to users and creators.",
        },
        {
            question: "Why is Funs.ai built on Base Network?",
            answer: "Base Network, built on an Ethereum Layer 2 solution, allows Funs.AI to handle a higher volume of transactions without the congestion issues commonly found on the main Ethereum Network. This capability not only results in much lower gas fees but also ensures faster transaction executions. Additionally, Base Network inherits the robust security features of the Ethereum mainnet, ensuring the safety and integrity of all transactions and data on the Funs.AI platform. Furthermore, the Base Network maintains compatibility with Ethereum, allowing seamless integration with existing Ethereum-based applications, assets, and smart contracts. These features enable Funs.AI to offer a scalable, cost-effective, secure, and efficient platform for its users and creators, making Base Network the ideal choice for building Funs.AI.",
        },
        {
            question: "How can I contribute to Funs.ai as a MVP tester?",
            answer: "As an MVP tester for Funs.ai, you play a crucial role in ensuring the platform is robust and user-friendly, with the opportunity to earn up to a $1000 bonus. Actively test all features, report bugs, and provide detailed descriptions. Evaluate the user interface and experience, offering feedback on design and usability. Assess platform performance, including loading times and system stability under different conditions. Provide constructive feedback on feature functionality and suggest enhancements. Maintain open communication with the development team, collaborate with other testers, and document your findings. Additionally, focus on security and compliance, reporting any vulnerabilities or risks. Your contributions help shape Funs.ai into a successful platform and earn you significant rewards.",
        },
    ];
    return (
        <div
            id="faq"
            className="relative bg-background-radial-gradient pt-3 md:pt-8 pb-8 md:pb-20 h-fit flex items-center flex-col"
        >
            <img
                className="absolute mt-8 ml-7  w-8 md:w-16"
                src="/assets/home/rocket.svg"
                alt="rocketImage"
            />
            <h1 className="absolute mt-16 font-extrabold z-20 text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                FAQ
            </h1>

            <img
                className="absolute mt-12 md:mt-7 mr-9 z-10 w-[100px] md:w-[240px]"
                src="/assets/home/tail.svg"
                alt="rocketTailImage"
            />
            <div>
                <div className="flex justify-center w-full ">
                    <img
                        className="absolute top-40"
                        src="/assets/clouds/clouds-group.png"
                        alt="cloudsImage"
                    />
                    <img className="absolute " src="/assets/home/stars.png" alt="starsImage" />
                </div>
            </div>
            <div className="flex justify-center w-full mt-32 md:mt-60 z-40 ">
                <div className="flex gap-y-2 md:gap-5 w-[95%] md:w-[60%] flex-col backdrop-blur-sm">
                    {qA.map((data, i) => (
                        <Accordin key={i} question={data.question} answer={data.answer} />
                    ))}
                </div>
            </div>
            {/* <div className="flex justify-center w-full mt-32 md:mt-60 z-40 ">
                <div className="flex gap-5 w-[49rem] flex-col backdrop-blur-sm">
                    {qA.map((data, i) => (
                        <Accordin key={i} question={data.question} answer={data.answer} />
                    ))}
                </div>
            </div> */}

            <div className="absolute top-[10%] -right-[10%] w-[455px] h-[455px] bg-[#D622DA95] rounded-full blur-[247px]" />
        </div>
    );
};

export default Faq;
