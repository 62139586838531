import { useEffect } from "react";
import employees from "./employees";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const TeamDetails = ({ showDetails, setshowDetails, employeeId, setEmployeeId }) => {
    useEffect(() => {
        if (showDetails) disableBodyScroll(true);
        else enableBodyScroll(true);
        return () => {
            enableBodyScroll(true);
        };
    }, [showDetails]);

    return (
        <div
            className={`duration-700 ${
                showDetails ? "animate-slide_in " : "animate-slide_out "
            } fixed top-0  bottom-0  right-0 w-[40%] md:max-w-[400px] md:w-[28%] overflow-auto  flex flex-col`}
        >
            <div className="bg-background-radial-gradient flex justify-between items-center text-white border-b border-[#E9FF00CC] p-2 md:p-6">
                <p className="font-semibold text-12-20">About</p>
                <button
                    onClick={() => {
                        setshowDetails(false);
                        setEmployeeId(null);
                    }}
                >
                    <img src="/assets/team/close_icon.svg" alt="" className="w-4 md:w-6" />
                </button>
            </div>
            <div className="flex-1 bg-background-radial-gradient  p-2 md:p-6 text-white">
                <img src={employees[employeeId]?.image} alt="" className="rounded-lg" />
                <h3 className="capitalize font-medium text-16-24 mt-4 md:mt-10">
                    {employees[employeeId]?.name}
                </h3>
                {/* <p className=" my-1 md:my-3 uppercase text-12-16">{employees[employeeId]?.position}</p> */}
                <p className=" mt-1 md:mt-3 md:mb-1 uppercase text-12-16">
                    {employees[employeeId]?.position}
                </p>
                {employees[employeeId]?.bio?.map((para, i) => (
                    <p className="text-12-16 mt-2" key={i}>
                        {para}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default TeamDetails;

// import { useEffect } from "react";
// import employees from "./employees";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

// const TeamDetails = ({ showDetails, setshowDetails, employeeId, setEmployeeId }) => {
//     useEffect(() => {
//         if (showDetails) disableBodyScroll(true);
//         else enableBodyScroll(true);
//         return () => {
//             enableBodyScroll(true);
//         };
//     }, [showDetails]);

//     return (
//         <div
//             className={`duration-700 ${
//                 showDetails ? "animate-slide_in " : "animate-slide_out "
//             } fixed top-0  bottom-0  right-0 w-[40%] md:max-w-[400px] md:w-[28%] overflow-auto`}
//         >
//             <div className="bg-background-radial-gradient flex justify-between items-center text-white border-b border-[#E9FF00CC] p-2 md:p-6">
//                 <p className="font-semibold text-12-20">About</p>
//                 <button
//                     onClick={() => {
//                         setshowDetails(false);
//                         setEmployeeId(null);
//                     }}
//                 >
//                     <img src="/assets/team/close_icon.svg" alt="" className="w-4 md:w-6" />
//                 </button>
//             </div>
//             <div className="min-h-screen bg-background-radial-gradient  p-2 md:p-6 text-white">
//                 <img src={employees[employeeId]?.image} alt="" className="rounded-lg" />
//                 <h3 className="capitalize font-medium text-16-24 mt-4 md:mt-10">
//                     {employees[employeeId]?.name}
//                 </h3>
//                 {/* <p className=" my-1 md:my-3 uppercase text-12-16">{employees[employeeId]?.position}</p> */}
//                 <p className=" mt-1 md:mt-3 md:mb-1 uppercase text-12-16">{employees[employeeId]?.position}</p>
//                 {employees[employeeId]?.bio?.map((para, i) => (
//                     <p className="text-12-16 mt-2" key={i}>{para}</p>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default TeamDetails;
