import Story from "../components/Story";
import Faq from "../components/Faq";
// import Story from "../components/Story"
import WhatIsFunsAI from "../components/WhatIsFunsAI";
import OurEcoSystem from "../components/OurEcoSystem";
import AboutUs from "../components/AboutUs";
import FoundingTeam from "../components/FoundingTeam";
import Footer from "../components/Footer";
import StickyBar from "../components/StickyBar";
import { useState } from "react";
import Universe from "../components/Universe";
import Partners from "../components/Partners";

const Home = () => {
    return (
        <div className="">
            <StickyBar />
            <WhatIsFunsAI />
            <OurEcoSystem />a
            <Universe />
            <Story />
            <Faq />
            <AboutUs />
            <FoundingTeam />
            <Partners />
            <Footer />
        </div>
    );
};

export default Home;

//     return (
//         <div>
//             <section>
//                 <Universe />
//             </section>
//             <section>
//                 <Story />
//             </section>
//             <section>
//                 <WhatIsFunsAI />
//                 <OurEcoSystem />
//             </section>
//             <section>
//                 <Faq />
//             </section>
//             <section>
//                 <AboutUs />
//             </section>
//             <section>
//                 <FoundingTeam />
//                 <Footer />
//             </section>
//             <StickyBar />
//         </div>
//     );