import React, { useState } from 'react'

const Accordin = ({answer,question}) => {

    const [accordinOpen, setAccordinOpen] = useState(false)
    const handleAccordinState = () => {
        setAccordinOpen(!accordinOpen)
    }
    return (
        <div className='p-3 md:p-7 rounded-lg md:rounded-2xl gradient'>
            <button className='w-full flex justify-between items-center' onClick={handleAccordinState} >
                <span className='text-12-20 font-medium text-white text-start'>{question}</span>
                {!accordinOpen ? <img src="/assets/home/plusIcon.svg" alt="plusIcon" className='w-3 md:w-6' />:<img src="/assets/home/negativeIcon.svg" alt="negativeIcon" className='w-3 md:w-6'/>}

            </button>
            <div className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm  ${accordinOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
                <div className='overflow-hidden text-10-16 font-medium text-white mt-1 md:mt-3'>
                   {answer}
                </div>
            </div>

        </div>
    )
}


export default Accordin