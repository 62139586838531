const FoundingTeamCard = ({ id, image, name, position, setshowDetails, setEmployeeId }) => {

    const handleClick = () => {
        setEmployeeId(id);
        setshowDetails(true);
    }

    return (
        <div
            className="bg-[#ffffff0d] rounded-2xl overflow-hidden p-5 hover:cursor-pointer duration-700 hover:shadow-[-3px_-3px_2px_#E9FF00] w-fit justify-self-center "
            onClick={handleClick}
        >
            <div className="mx-auto  w-fit">
                <img src={image} alt="" className="rounded-lg overflow-hidden " />
                <h2 className="text-white text-xl font-medium mt-5 capitalize">{name}</h2>
                <p className="text-white text-xs uppercase">{position}</p>
            </div>
        </div>
    );
};

export default FoundingTeamCard;
