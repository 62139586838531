import FoundingTeamCard from "./FoundingTeamCard";
import employees from "./employees";
import TeamDetails from "./TeamDetails";
import { useEffect, useState } from "react";

const FoundingTeam = () => {
    const [showDetails, setshowDetails] = useState(false);
    const [employeeId, setEmployeeId] = useState(null);

    return (
        <div className=" bg-background-radial-gradient min-h-screen py-16 md:py-40 w-full ">
            <div className=" w-[90%] mx-auto ">
                <h1 className=" font-extrabold text-[#E9FF00] text-28-56 text-[28px] text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                    FOUNDING TEAM
                </h1>
                <div className="relative grid grid-cols-2  md:grid-cols-4 md:gap-10 mt-8 md:mt-[60px] ">
                    {employees.map((employee, i) => {
                        return (
                            <FoundingTeamCard
                                key={i}
                                id={i}
                                image={employee.image}
                                name={employee.name}
                                position={employee.position}
                                setshowDetails={setshowDetails}
                                setEmployeeId={setEmployeeId}
                            />
                        );
                    })}
                </div>
            </div>
            {showDetails && (
                <TeamDetails
                    showDetails={showDetails}
                    setshowDetails={setshowDetails}
                    employeeId={employeeId}
                    setEmployeeId={setEmployeeId}
                />
            )}
        </div>
    );
};

export default FoundingTeam;
